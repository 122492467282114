@layer utilities {
  .prose {
    max-width: 75ch;
    color: theme('colors.gray.900');
  }

  .prose > div:first-child > hr:first-child {
    display: none;
  }

  .prose [class~='lead'] {
    margin-top: 1.2em;
    margin-bottom: 1.2em;

    @apply text-justify text-gray-800 font-serif text-base font-bold tracking-wide lg:text-lg;
    text-indent: 0;
  }

  .prose a {
    @apply text-blue-500 no-underline font-bold tracking-tight word-break-break-word;
  }

  .prose strong {
    color: theme('colors.gray.900');

    /* margin: 0 -0.4em;
      padding: 0.1em 0.4em;
      border-radius: 0.8em 0.3em;
      background: transparent;
      background-image: linear-gradient(
        to right,
        rgba(255, 225, 0, 0.1),
        rgba(255, 225, 0, 0.7) 4%,
        rgba(255, 225, 0, 0.3)
      );
      -webkit-box-decoration-break: clone;
      box-decoration-break: clone; */

    background: linear-gradient(
        104deg,
        rgba(130, 255, 173, 0) 0.9%,
        rgba(130, 255, 173, 1.25) 2.4%,
        rgba(130, 255, 173, 0.5) 5.8%,
        rgba(130, 255, 173, 0.1) 93%,
        rgba(130, 255, 173, 0.7) 96%,
        rgba(130, 255, 1732, 0) 98%
      ),
      linear-gradient(183deg, rgba(130, 255, 173, 0) 0%, rgba(130, 255, 173, 0.3) 7.9%, rgba(130, 255, 173, 0) 15%);
    padding: 0.1em 0.4em;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
    margin: 0;
    border-radius: 7.5px;
    text-shadow: -12px 12px 9.8px rgba(130, 255, 173, 0.7), 21px -18.1px 7.3px rgba(255, 255, 255, 0),
      -18.1px -27.3px 30px rgba(255, 255, 255, 1);
  }

  .prose ol > li {
    position: relative;
    padding-left: 1.75em;

    @apply before:counter-result before:text-stroke before:top-0 my-0 before:font-sans before:text-4xl before:proportional-nums before:font-semibold before:leading-none;
  }

  .prose .footnotes ol > li {
    @apply before:counter-result before:text-[1.1rem];
  }

  .prose ul > li {
    position: relative;
    padding-left: 1.75em;
  }

  .prose ul > li:before {
    content: '';
    position: absolute;
    background-color: #bababa;
    border-radius: 50%;
    width: 0.375em;
    height: 0.375em;
    top: calc(0.875em - 0.1875em);
    left: 0.25em;
  }

  /* .prose hr {
    border-color: #f0f0f0;
    border-top-width: 1px;
    margin-top: 3em;
    margin-bottom: 3em;
  } */

  .prose blockquote,
  .prose p.important {
    margin-top: 1.6em;
    margin-bottom: 1.6em;

    quotes: auto;
    @apply indent-0 relative text-transparent bg-no-repeat bg-clip-text;
    @apply -ml-7 -mr-7 pl-8 pr-8 py-4 border-b border-l-0 border-r-0 border-t border-b-blue-500 border-t-pink-500 sm:-ml-6 sm:-mr-32 sm:pr-32 md:-mr-32 lg:-mr-12 lg:pr-20;

    &,
    &::before,
    &::after {
      @apply bg-gradient-to-t from-blue-500 to-pink-500;
    }

    &::before {
      content: '';
      @apply absolute left-0 top-0 block w-2 h-full;
    }

    &::after {
      @apply absolute right-0 top-0 leading-tight text-7xl font-serif font-black text-transparent bg-no-repeat bg-clip-text;
    }

    & br {
      content: '';
      @apply my-[1ex] block;
    }
  }

  .prose blockquote {
    &::after {
      content: '”';
    }
  }

  .prose p.important {
    @apply indent-0;

    &::after {
      content: '!';
      @apply font-sans text-6xl -top-2 right-4;
    }
  }

  .prose blockquote p:first-of-type:before {
    content: open-quote;
  }

  .prose blockquote p:last-of-type:after {
    content: close-quote;
  }

  .prose blockquote p,
  .prose ol p,
  .prose ul p {
    @apply text-left;
    text-indent: 0;
    hyphens: none;
    -ms-hyphens: none;
    -moz-hyphens: none;
    -webkit-hyphens: none;
  }

  .prose h1 {
    color: theme('colors.gray.900');
    font-weight: 800;
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: 0.8888889em;
    line-height: 1.1111111;
  }

  .prose h2 {
    color: theme('colors.gray.900');
    font-weight: 700;
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.3333333;
  }

  .prose h3 {
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }

  .prose h3,
  .prose h4 {
    color: theme('colors.gray.900');
    font-weight: 600;
  }

  .prose h4 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
  }

  .prose figure figcaption {
    color: theme('colors.gray.600');
    font-size: 0.875em;
    line-height: 1.4285714;
    margin-top: 0.8571429em;
    text-align: center;
  }

  .prose code {
    color: theme('colors.gray.900');
    font-weight: 600;
    font-size: 0.875em;
  }

  .prose code:after,
  .prose code:before {
    content: '`';
  }

  .prose a code {
    color: theme('colors.gray.900');
  }

  .prose pre {
    color: #f0f0f0;
    background-color: #3b3b3b;
    overflow-x: auto;
    font-size: 0.875em;
    line-height: 1.7142857;
    margin-top: 1.7142857em;
    margin-bottom: 1.7142857em;
    border-radius: 0.375rem;
    padding: 0.8571429em 1.1428571em;
  }

  .prose pre code {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: 400;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }

  .prose pre code:after,
  .prose pre code:before {
    content: none;
  }

  .prose table {
    width: 100%;
    table-layout: auto;
    text-align: left;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 0.875em;
    line-height: 1.7142857;
  }

  .prose thead {
    color: theme('colors.gray.900');
    font-weight: 600;
    border-bottom-width: 1px;
    border-bottom-color: #bababa;
  }

  .prose thead th {
    vertical-align: bottom;
    padding-right: 0.5714286em;
    padding-bottom: 0.5714286em;
    padding-left: 0.5714286em;
  }

  .prose tbody tr {
    border-bottom-width: 1px;
    border-bottom-color: #f0f0f0;
  }

  .prose tbody tr:last-child {
    border-bottom-width: 0;
  }

  .prose tbody td {
    vertical-align: top;
    padding: 0.5714286em;
  }

  .prose {
    font-size: 1rem;
    line-height: 1.75;
  }

  .prose p,
  dl,
  .prose dl {
    text-indent: 2rem;
    hyphens: auto;
    -ms-hyphens: auto;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    @apply my-0 text-justify font-serif oldstyle-nums font-normal leading-normal lg:text-lg lg:leading-normal;
  }

  .prose p h1,
  .prose p h2,
  .prose p h3,
  .prose p h4 {
    @apply indent-0;
  }

  .prose figure,
  .prose img,
  .prose .img,
  .prose video {
    @apply mx-auto my-8;
  }

  .prose img,
  .prose .img {
    @apply hover:shadow-md shadow-sm transition-shadow;

    &.aligncenter {
      @apply mx-auto;
    }

    &.alignleft {
      @apply float-left mr-5;
    }

    &.alignright {
      @apply float-right ml-5;
    }
  }

  .prose .newsletter-form {
    @apply -mx-6 mt-8 bg-white border sm:mx-auto;
  }

  .prose figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  .prose h2 code {
    font-size: 0.875em;
  }

  .prose h3 code {
    font-size: 0.9em;
  }

  .prose ol,
  .prose ul {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    @apply counter-reset mt-3;
  }

  .prose li {
    @apply counter-increment pt-[0.5ex] my-0 font-serif leading-normal;
  }

  .prose ul p,
  .prose ol p {
    @apply contents;
  }

  .prose > ol > li > :first-child,
  .prose > ul > li > :first-child {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }

  .prose ol ol,
  .prose ol ul,
  .prose ul ol,
  .prose ul ul {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }

  .prose h2 + *,
  .prose h3 + *,
  .prose h4 + *,
  .prose > :first-child {
    /* .prose > div:first-child > :first-of-type { */
    margin-top: 0;
  }

  .prose thead th:first-child,
  .prose tbody td:first-child {
    padding-left: 0;
  }

  .prose thead th:last-child,
  .prose tbody td:last-child {
    padding-right: 0;
  }

  .prose > :last-child {
    margin-bottom: 0;
  }

  .prose-lg {
    font-size: 1.125rem;
    line-height: 1.7777778;
  }

  .prose-xl {
    font-size: 1.25rem;
    line-height: 1.8;
  }

  .prose-xl dl,
  .prose-xl p {
    @apply my-0;
    text-indent: 3rem;
  }

  .prose-xl blockquote {
    @apply pl-8;
  }

  .prose-xl ol,
  .prose-xl ul {
    @apply mt-3;
    @apply counter-reset;
  }

  .prose-xl h2 {
    font-size: 2.441rem;
    margin: 3rem 0 1.38rem;
    line-height: 1.3;
  }

  .prose-xl h3 {
    font-size: 1.953rem;
    margin: 3rem 0 1.38rem;
    line-height: 1.3;
  }

  .prose-xl h4 {
    font-size: 1.563rem;
    margin: 3rem 0 1.38rem;
    line-height: 1.3;
  }

  .prose,
  .prose-xl {
    & figure {
      & img,
      & .img {
        @apply mb-0;
      }

      & figcaption p {
        @apply indent-0 text-center text-sm;
      }
    }
  }

  .prose p,
  li,
  blockquote {
    @apply text-base lg:text-lg;
  }
}
