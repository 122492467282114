@font-face {
  font-family: 'typeofweb-fallback-sans';
  src: local('Arial'), local('Helvetica');
  advance-override: 146%;
  ascent-override: 93.5%;
  descent-override: 26.5%;
  line-gap-override: 0%;
}

@font-face {
  font-family: 'typeofweb-fallback-serif';
  src: local('Times New Roman'), local('Times');
  advance-override: calc(291.4% / 2);
  ascent-override: calc(196.8% / 2);
  descent-override: calc(54.6% / 2);
  line-gap-override: 0%;
}

@font-face {
  font-family: 'typeofweb-fallback-mono';
  src: local('Courier New'), local('Courier');
  advance-override: 60%;
  ascent-override: 93.5%;
  descent-override: 26.5%;
  line-gap-override: 0;
}

html:not(.fonts-loaded) {
  & .font-sans,
  & .prose ol > li::before {
    font-family: 'typeofweb-fallback-sans', sans-serif !important;
    letter-spacing: 0.1px !important;
    word-spacing: 0.2px !important;
  }

  & .font-serif,
  & .prose [class~='lead'],
  & .prose p,
  & .prose ol > li {
    font-family: 'typeofweb-fallback-serif', serif !important;
    letter-spacing: 1.93px !important;
    word-spacing: -2px !important;
    font-weight: 600;
  }

  & .font-mono {
    font-family: 'typeofweb-fallback-mono', monospace !important;
    letter-spacing: 0 !important;
    word-spacing: 0 !important;
  }
}

@font-face {
  font-family: 'Merriweather';
  src: local('Merriweather Regular'), local('Merriweather-Regular'), local('Merriweather'),
    url('/fonts/merriweather-12ptregular-typeofweb.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: local('Merriweather Italic'), local('Merriweather-Italic'), local('Merriweather'),
    url('/fonts/merriweather-12ptitalic-typeofweb.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: local('Merriweather Bold'), local('Merriweather-Bold'), local('Merriweather'),
    url('/fonts/merriweather-12ptbold-typeofweb.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Merriweather';
  src: local('Merriweather Bold Italic'), local('Merriweather-Bold-Italic'), local('Merriweather'),
    url('/fonts/merriweather-12ptbolditalic-typeofweb.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  src: local('FiraSans Regular'), local('FiraSans-Regular'), local('Fira Sans Regular'), local('Fira-Sans-Regular'),
    local('Fira Sans'), local('Fira-Sans'), url('/fonts/firasans-regular-typeofweb.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fira Sans';
  src: local('FiraSans Semibold'), local('FiraSans-Semibold'), local('Fira Sans Semibold'), local('Fira-Sans-Semibold'),
    local('Fira Sans'), local('Fira-Sans'), url('/fonts/firasans-semibold-typeofweb.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Fira Mono';
  src: local('FiraMono Regular'), local('FiraMono-Regular'), local('Fira Mono Regular'), local('Fira-Mono-Regular'),
    local('Fira Mono'), url('/fonts/firamono-regular-typeofweb.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
