/* PrismJS 1.24.1
https://prismjs.com/download.html#themes=prism&languages=markup+css+clike+javascript+abap+abnf+actionscript+ada+agda+al+antlr4+apacheconf+apex+apl+applescript+aql+arduino+arff+asciidoc+aspnet+asm6502+autohotkey+autoit+bash+basic+batch+bbcode+birb+bison+bnf+brainfuck+brightscript+bro+bsl+c+csharp+cpp+cfscript+chaiscript+cil+clojure+cmake+cobol+coffeescript+concurnas+csp+coq+crystal+css-extras+csv+cypher+d+dart+dataweave+dax+dhall+diff+django+dns-zone-file+docker+dot+ebnf+editorconfig+eiffel+ejs+elixir+elm+etlua+erb+erlang+excel-formula+fsharp+factor+false+firestore-security-rules+flow+fortran+ftl+gml+gcode+gdscript+gedcom+gherkin+git+glsl+go+graphql+groovy+haml+handlebars+haskell+haxe+hcl+hlsl+hoon+http+hpkp+hsts+ichigojam+icon+icu-message-format+idris+ignore+inform7+ini+io+j+java+javadoc+javadoclike+javastacktrace+jexl+jolie+jq+jsdoc+js-extras+json+json5+jsonp+jsstacktrace+js-templates+julia+keyman+kotlin+kumir+latex+latte+less+lilypond+liquid+lisp+livescript+llvm+log+lolcode+lua+makefile+markdown+markup-templating+matlab+mel+mizar+mongodb+monkey+moonscript+n1ql+n4js+nand2tetris-hdl+naniscript+nasm+neon+nevod+nginx+nim+nix+nsis+objectivec+ocaml+opencl+openqasm+oz+parigp+parser+pascal+pascaligo+psl+pcaxis+peoplecode+perl+php+phpdoc+php-extras+plsql+powerquery+powershell+processing+prolog+promql+properties+protobuf+pug+puppet+pure+purebasic+purescript+python+qsharp+q+qml+qore+r+racket+jsx+tsx+reason+regex+rego+renpy+rest+rip+roboconf+robotframework+ruby+rust+sas+sass+scss+scala+scheme+shell-session+smali+smalltalk+smarty+sml+solidity+solution-file+soy+sparql+splunk-spl+sqf+sql+squirrel+stan+iecst+stylus+swift+t4-templating+t4-cs+t4-vb+tap+tcl+tt2+textile+toml+turtle+twig+typescript+typoscript+unrealscript+uri+v+vala+vbnet+velocity+verilog+vhdl+vim+visual-basic+warpscript+wasm+wiki+wolfram+xeora+xml-doc+xojo+xquery+yaml+yang+zig */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */
pre[data-lang]:not(pre[data-lang='']) {
  position: relative;
  &::after {
    content: attr(data-lang);
    @apply absolute right-0 top-0 block px-1 py-0.5 font-mono text-xs font-bold rounded-bl-md rounded-tr-md;
  }
}

pre[data-lang='ts']::after {
  background-color: #3178c6;
  color: white;
}

pre[data-lang='bash']::after {
  background-color: black;
  color: white;
}

pre[data-lang='jsx']::after,
pre[data-lang='tsx']::after {
  background-color: #61dafb;
  color: #20232a;
}

pre[data-lang='json']::after {
  background-color: #f4f4f4;
  color: #20232a;
}

pre[data-lang='javascript']::after,
pre[data-lang='js']::after {
  background-color: #f0db4f;
  color: #323330;
}

pre[data-lang='html']::after {
  background-color: #f06529;
  color: white;
}

pre[data-lang='css']::after {
  background-color: #2965f1;
  color: white;
}

pre[data-lang='reason']::after {
  background-color: #d74938;
  color: white;
}

code[class*='language-'],
pre[class*='language-'] {
  color: black;
  background: none;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;

  -moz-tab-size: 2;
  -o-tab-size: 2;
  tab-size: 2;

  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre[class*='language-'],
code[class*='language-'] {
  &::selection,
  & ::selection,
  &::-moz-selection,
  & ::-moz-selection {
    text-shadow: none;
    background: #b3d4fc;
  }
}

/* Code blocks */
pre[class*='language-'] {
  padding: 0;
  margin: 2em 0;
  overflow: hidden;
}

pre[class*='language-'] > code {
  overflow-x: auto;
  max-width: 100%;
  display: block;
  padding: 1.2em;
}

:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  @apply bg-gray-100 shadow;
  --tw-shadow: inset 0 2px 4px 0 rgba(0, 0, 0, 0.06), 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

/* Inline code */
:not(pre) > code[class*='language-'] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
  color: theme('colors.gray.500');
}

.token.punctuation {
  color: #999;
  color: theme('colors.gray.600');
}

.token.namespace {
  opacity: 0.7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #905;
  color: theme('colors.pink.600');
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: #690;
  color: theme('colors.green.700');
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #9a6e3a;
  /* color: theme('colors.pink.900'); */
}

.token.atrule,
.token.attr-value,
.token.keyword {
  color: #07a;
  color: theme('colors.pink.500');
}

.token.function,
.token.class-name {
  color: #dd4a68;
  color: theme('colors.blue.400');
}

.token.regex,
.token.important,
.token.variable {
  color: #e90;
}

.token.important,
.token.bold {
  font-weight: bold;
}
.token.italic {
  font-style: italic;
}

.token.entity {
  cursor: help;
}
